





















import { defineComponent } from "@vue/composition-api";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";

export default defineComponent({
  name: "DeleteModal",
  props: {
    title: {
      type: String,
      default: "Изменения не будут сохранены. Вы уверены, что хотите закрыть окно редактирования?",
    },
    icon: {
      type: String,
    },
    yesBtnTitle: {
      type: String,
      default: "Да",
    },
    noBtnTitle: {
      type: String,
      default: "Нет",
    },
    cbOnDelete: {
      type: Function,
      default: () => 1,
    },
  },
  methods: {
    closeModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(false, ""));
    },
    deleteCb() {
      (this as { cbOnDelete: () => void }).cbOnDelete?.();
      this.closeModal();
    },
  },
});
